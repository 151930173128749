 /* --------------------- */
 /* Dark theme Goes Here */
 
 .darktheme .thankyou-container {
     background-image: url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape.png), url(https://all-frontend-assets.s3.amazonaws.com/pece-marketing-landingpage/assets/images/PeceMarketingThankYouDarkShape2.png);
     background-color: #170036;
     background-position: -85px -158px, 627px 480px;
     display: flex;
     justify-content: center;
     background-repeat: no-repeat;
     flex-wrap: wrap;
     width: 100%;
     height: 100vh;
 }
 
 .darktheme .thankyou-container .thankyou-block {
     text-align: center;
     line-height: 3px;
     padding: 30px;
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .darktheme .thankyou-container .thankyou-block .content {
     color: white;
     text-align: center;
     line-height: 3px;
     max-width: 800px;
     padding: 0;
 }
 
 .darktheme .thankyou-container .thankyou-block .content h1 {
     font-size: 32px;
     line-height: 36px;
 }
 
 .darktheme .thankyou-container .thankyou-block .content p {
     font-size: 22px;
     color: #fff;
     margin: 10px 0px;
     /* line-height: 13px; */
 }


 /* < <<-----//////////-----Divine-Theme-Thankyou-Page-CSS-----//////////----->> > */
 .divinetheme .thankyou-container {
    background: url(https://all-frontend-assets.s3.us-east-1.amazonaws.com/transcendentpagan/assets/images/divine_thankyou_page_bg_img.webp), #000000;
    background-repeat: no-repeat;
    background-size: cover, cover;
    background-position: bottom center, center;
 }
 .divinetheme .thankyou-container .thankyou-block .content h1 {
    font-size: 65px;
    line-height: 70px;
    color: #ff9815;
    text-align: center;
    text-transform: uppercase;
 }
 .divinetheme .thankyou-container .thankyou-block .content {
    max-width: 1385px;
 }
 .divinetheme .thankyou-container .thankyou-block .content p {
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
    color: #d5d5d5;
    text-wrap: balance;
 }
 .divinetheme .thankyou-container .thankyou-block .content p span {
    color: #00b4ff;
 }




 /* ------------ Responsive ----------------- */

 @media (max-width:1199px) {
    .divinetheme .thankyou-container .thankyou-block .content h1 {
        font-size: 45px;
        line-height: 50px;
    }
    .divinetheme .thankyou-container .thankyou-block .content p {
        font-size: 26px;
        line-height: 32px;
    }
 }
 
 @media (max-width:701px) {
     .darktheme .thankyou-container .thankyou-block .content {
         max-width: 700px;
         line-height: 20px !important;
     }
 }
 
 @media screen and (max-width:500px) {
     .darktheme .thankyou-container .thankyou-block .content h1 {
         font-size: 30px;
         line-height: 30px;
     }
     .darktheme .thankyou-container .thankyou-block .content p {
         font-size: 17px;
         margin-bottom: 0px;
     }
 }
 /* ------------------------------------------------ */
 /* Dark theme Ends Here */
 /* --------------------- */