



/* .bookingModal .modalblock .MuiBox-root {
    width: 1285px !important;
} */

.information_details_sec span {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    color: #1648a5;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 15px;
}