.logOut{
    cursor: pointer;
    background: linear-gradient(160deg, #00abff 0%, #2a2a2a 100%);
    border-radius: 50px !important;
    padding: 4px 30px 4px !important;
    text-decoration: none;
    width: auto;
    margin: 0px !important;
    box-shadow: none;
    -webkit-transition: none!important;
    font-size: 17px !important;
    color: #fff !important;
    text-transform: uppercase !important;
    margin-top: 15px;
    border: 1px solid #dfe8ed !important;
}